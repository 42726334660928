<template>
  <div class="jurisdiction-selector">
    <!-- Country Selector Section -->
    <div class="country-selector">
      <p class="jurisdiction-limit">
        Max of 3 Jurisdictions ({{
          onlyCountry ? selectedStates.length : selectedCountries.length
        }}/3)
      </p>

      <div class="country-list">
        <ul style="padding-left: 0.5rem">
          <li
            v-for="(flag, countryCode) in countryImages"
            :key="countryCode"
            @click="!onlyCountry && selectCountry(countryCode)"
            class="country-item"
            :class="{ 'is-selected': selectedCountries.includes(countryCode) }"
          >
            <div class="d-flex align-items-center">
              <img :src="flag" alt="Country Flag" class="country-flag" />
              <span class="country-name">
                {{ countryCode }}
              </span>
            </div>
            <div class="d-flex align-items-center" v-if="!onlyCountry">
              <span class="material-symbols-rounded chev-icon">
                chevron_right
              </span>
            </div>
            <input
              v-else
              type="checkbox"
              :id="countryCode"
              :value="countryCode"
              :checked="selectedStates[countryCode]"
              @change="toggleCountrySelection(countryCode)"
            />
          </li>
        </ul>
      </div>
    </div>

    <!-- State Selector Section -->
    <div
      v-if="selectedCountries.length > 0 && !onlyCountry"
      class="state-selector"
    >
      <p class="jurisdiction-limit">
        Max of 3 States ({{ selectedStates.length }}/3)
      </p>
      <label for="stateSearch" class="visually-hidden">Search State</label>
      <input
        id="stateSearch"
        type="text"
        class="state-search"
        placeholder="Search State here..."
        v-model="stateSearch"
      />
      <div class="state-list">
        <div v-for="state in finalS" :key="state" class="state-item">
          <div class="d-flex align-items-center">
            <input
              :id="`state-${state}`"
              type="checkbox"
              :value="state"
              v-model="selectedStates"
              :disabled="
                selectedStates.length >= 3 && !selectedStates.includes(state)
              "
              class="custom-checkbox"
            />
            <label :for="`state-${state}`">{{ state }}</label>
          </div>
        </div>
      </div>
    </div>

    <!-- Court Selector Section -->
    <div
      class="court-selector"
      v-if="
        enableCourts &&
        selectedStates.length > 0 &&
        showCourtSelector &&
        !onlyCountry
      "
    >
      <p class="jurisdiction-limit">Court Name ({{ selectedCourts.length }})</p>
      <label for="courtSearch" class="visually-hidden">Search Court</label>
      <input
        id="courtSearch"
        type="text"
        class="court-search"
        placeholder="Type Court Name here..."
        v-model="courtSearch"
      />
      <div class="court-list">
        <div v-if="filteredCourts.length > 0">
          <label class="court-item">
            <input
              type="checkbox"
              v-model="selectAll"
              @change="toggleSelectAll"
            />
            <span>Select All Courts and Institutions</span>
          </label>
          <div
            v-for="court in filteredCourts"
            :key="court.id"
            class="court-item"
          >
            <label class="d-flex align-items-center">
              <input
                type="checkbox"
                :value="court.id"
                v-model="selectedCourts"
                @change="updateSelectAllState"
              />
              {{ court.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import courtData from "@/store/courtname";
import countryImages from "@/assets/country/index";

export default {
  props: {
    enableCourts: {
      type: Boolean,
      default: true,
    },
    onlyCountry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCountries: [],
      selectedStates: [],
      selectedCourts: [],
      stateSearch: "",
      courtSearch: "",
      selectAll: false,
      countryImages,
      maxJurisdictions: 3,
      jurisdictionMap: {},
      countryMapping: {
        AUS: "Australia",
        USA: "USA",
        CAN: "Canada",
        NZ: "New Zealand",
        SG: "Singapore",
        MY: "Malaysia",
        UK: "United Kingdom",
      },
    };
  },
  watch: {
    selectedCourts(newVal) {
      if (!this.onlyCountry) {
        this.selectAll = newVal.length === this.filteredCourts.length; // Update selectAll whenever selectedCourts changes
        this.updateSelectedjuri();
      }
    },
    selectedCountries() {
      if (!this.onlyCountry) {
        this.updateSelectedjuri();
      }
    },
    selectedStates() {
      if (!this.onlyCountry) {
        this.updateSelectedjuri();
      }
    },
  },
  computed: {
    showCourtSelector() {
      return this.selectedStates.length > 0; // Show courts only when states are selected
    },
    finalS() {
      const selectedCountry =
        this.selectedCountries[this.selectedCountries.length - 1]; // Last selected country
      const countryData = courtData[selectedCountry] || {};
      return countryData.states
        .map((state) => state.split("|")[0].trim()) // Clean state names
        .filter((state) =>
          state.toLowerCase().includes(this.stateSearch.toLowerCase())
        );
    },
    filteredCourts() {
      const selectedCountry =
        this.selectedCountries[this.selectedCountries.length - 1];
      if (selectedCountry && this.selectedStates.length > 0) {
        let courts = [];
        const countryCourts = courtData[selectedCountry]?.courts || {};

        this.selectedStates.forEach((selectedState) => {
          Object.keys(countryCourts).forEach((courtCategory) => {
            const categoryCourts = countryCourts[courtCategory];
            const stateCourts = categoryCourts.filter((court) =>
              court.name.toLowerCase().includes(selectedState.toLowerCase())
            );
            if (stateCourts.length > 0) {
              courts = courts.concat(stateCourts);
            }
          });
        });

        return courts.filter((court) =>
          court.name.toLowerCase().includes(this.courtSearch.toLowerCase())
        );
      }
      return [];
    },
  },
  methods: {
    toggleCountrySelection(countryCode) {
      const countryToSelect = this.countryMapping[countryCode];
      const countryIndex = this.selectedStates.indexOf(countryToSelect);

      if (countryIndex !== -1) {
        this.selectedStates.splice(countryIndex, 1);
      } else {
        this.selectedStates.push(countryToSelect);
      }

      this.$emit("update-jurisdiction", this.selectedStates);
    },
    selectCountry(countryCode) {
      if (this.selectedCountries.includes(countryCode)) {
        this.selectedCountries = this.selectedCountries.filter(
          (c) => c !== countryCode
        );

        if (this.jurisdictionMap[countryCode]) {
          this.selectedStates = this.selectedStates.filter(
            (state) => !this.jurisdictionMap[countryCode].states.includes(state)
          );
          this.selectedCourts = this.selectedCourts.filter(
            (court) => !this.jurisdictionMap[countryCode].courts.includes(court)
          );
          delete this.jurisdictionMap[countryCode];
        }
      } else if (this.selectedCountries.length < this.maxJurisdictions) {
        this.selectedCountries.push(countryCode);

        this.jurisdictionMap[countryCode] = {
          states: [],
          courts: [],
        };
      }
      this.updateSelectedjuri();
    },
    toggleSelectAll() {
      const selectedCountry =
        this.selectedCountries[this.selectedCountries.length - 1];
      if (this.selectAll) {
        this.jurisdictionMap[selectedCountry].courts = this.filteredCourts.map(
          (court) => court.id
        );
        this.selectedCourts = this.filteredCourts.map((court) => court.id);
      } else {
        this.jurisdictionMap[selectedCountry].courts = [];
        this.selectedCourts = [];
      }
      this.updateSelectedjuri();
    },
    updateSelectAllState() {
      const selectedCountry =
        this.selectedCountries[this.selectedCountries.length - 1];

      this.selectAll =
        this.filteredCourts.length > 0 &&
        this.filteredCourts.every((court) =>
          this.selectedCourts.includes(court.id)
        );

      this.jurisdictionMap[selectedCountry].courts = this.selectedCourts;

      this.updateSelectedjuri();
    },
    updateSelectedjuri() {
      const jurisdictionMapping = {
        AUS: "Australia",
        USA: "USA",
        CAN: "Canada",
        NZ: "New Zealand",
        SG: "Singapore",
        MY: "Malaysia",
        UK: "United Kingdom",
      };

      this.selectedjuri = this.selectedCountries.map((countryAbbreviation) => {
        const fullCountryName =
          jurisdictionMapping[countryAbbreviation] || countryAbbreviation;

        return {
          j: fullCountryName,
          s: this.jurisdictionMap[countryAbbreviation]?.states || [],
          c: this.jurisdictionMap[countryAbbreviation]?.courts || [],
        };
      });

      this.$emit("update-jurisdiction", this.selectedjuri);
    },
  },
};
</script>

<style scoped>
.jurisdiction-selector {
  background: #fff;
  top: 100%;
  width: 100%;
  z-index: 1000;
  position: absolute;
  display: flex;
  gap: 20px;
  margin-top: 16px;
}

.country-selector,
.state-selector {
  width: 30%;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
}
.court-selector {
  width: 50%;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
}

.country-dropdown,
.state-search,
.court-search {
  display: flex;
  align-items: center;
  border: 1px solid #f2f3f3;
  border-radius: 4px;
  background: #fafbfc;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}

.country-flag,
.dropdown-icon {
  width: 18px;
  height: 18px;
}

.country-name {
  color: #86888d;
  flex-grow: 1;
  margin: 0 8px;
}

.country-list,
.state-list,
.court-list {
  margin: 0;
  padding: 0;
  margin-top: 8px;
  border-radius: 4px;
  background: #fff;
  max-height: 200px;
  overflow-y: auto;
  color: #86888d;
}

.country-item,
.state-item,
.court-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}
.country-item,
.state-item {
  justify-content: space-between;
}
.country-item:hover,
.state-item:hover,
.court-item:hover {
  background: #f2f3f3;
}

.state-search,
.court-search {
  margin: 0 auto;
  width: 90%;
  padding: 8px;
  margin-bottom: 8px;
}

.court-selector-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.selected-state {
  background: #fafbfc;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.select-all {
  font-weight: 600;
}

.select-all-icon {
  margin-right: 8px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .jurisdiction-selector {
    flex-direction: column;
  }
}

.jurisdiction-limit {
  padding: 7px 15px 0px 15px;
  font-size: 15px;
  color: #86888d !important;
  margin-top: 8px;
}
.chev-icon {
  color: #86888d;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Black, #383a3e);
  flex-shrink: 0;
  appearance: none;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: var(--primary);
}
input[type="checkbox"]:checked::after {
  content: "";
  display: block;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: relative;
  left: 5px;
}
</style>
